<template>
  <div>
    <b-card no-body>
      <b-overlay :show="loading" rounded="sm">
        <b-row class="">
          <b-col cols="12" xl="8">
            <div class="d-md-flex flex-row mb-2  text-md-left text-center">
              <b-button
                @click="$router.back()"
                class="shadow-sm font-weight-bold p-1 align-self-baseline float-left"
                variant="white"
              >
                <feather-icon icon="ChevronLeftIcon" size="20" />
              </b-button>
              <div class="mr-2">
                <b-avatar
                  :src="getUserImage(userDetails.id)"
                  class="mt-2 mr-3 mr-md-0"
                  size="100px"
                />
              </div>
              <div class="m-2 font-weight-bolder profile-text">
                <b-row>
                  <div class="d-flex justify-content-center mt-1">
                    <div class="mr-1">
                      <h2 class=" font-weight-700 ">
                      {{ userDetails.first_name }} {{ userDetails.last_name }}
                      </h2>
                    </div>
                    <div>
                      <b-alert
                      variant="primary"
                      style="margin-top:6px; margin-left: 4px;"
                      show
                      >
                        <small class="text-dark font-weight-700 mx-1 "
                          >{{ jobRoleName }}
                        </small>
                      </b-alert>
                    </div>
                    
                    
                  </div>
                  <!-- <div class="col-12 d-flex justify-content-center">

            </div> -->
                </b-row>
                <b-row style="margin-left:1px">
                  <!-- <b-col cols="12"> </b-col> -->
                  <b-col cols=" mt-1">
                    <b-row >
                      <div
                        class="d-flex justify-content-center justify-content-xl-end"
                        
                      >
                        <div class="d-flex align-items-center pr-bx" style="color:#171822">
                          <b-avatar variant="light-primary" rounded>
                            <feather-icon icon="CalendarIcon" size="18" />
                          </b-avatar>
                          <div class="ml-1">
                            <b-row v-if="editLeaveDaysShow">
                              <b-col class="d-flex">
                                <div>
                                  <h5 v-if="trackLeaveOnDays"
                                  class="font-weight-bolder"
                                  style="margin-top:10px; color: #171822; font-size:18px"
                                  >
                                    {{ allocatedLeaveDays }} days
                                  </h5>
                                  <h5 v-else
                                    class="font-weight-bolder mr-1"
                                    style="margin-top:10px; color: #171822; font-size:18px"
                                  >
                                    {{
                                      parseFloat(allocatedLeaveHours).toFixed(1)
                                    }}hrs
                                  </h5>
                                </div>
                                <div>
                                  
                                  <b-button
                                    size="20"
                                    variant="outline-white"
                                    class="btn-icon bg-white text-primary"
                                    @click="editLeaveDays()"
                                  >
                                    <feather-icon icon="EditIcon" size="15" />
                                  </b-button>
                                </div>
                              </b-col>
                              <!-- <b-col class="ml-1">
                                <b-button
                                  size="20"
                                  variant="outline-white"
                                  class="btn-icon bg-white text-primary"
                                  @click="editLeaveDays()"
                                >
                                  <feather-icon icon="EditIcon" size="15" />
                                </b-button>
                              </b-col> -->
                            </b-row>
                            <b-row v-else>
                              <b-col class="mb-0 mr-n4">
                                <b-input-group class="input-group-merge">
                                  <b-form-input
                                    v-model="editNoOfLeaveDays"
                                    size="sm"
                                    placeholder=""
                                    type="number"
                                  />
                                  <b-input-group-append is-text>
                                    <feather-icon
                                      style="cursor: pointer;"
                                      class="text-primary"
                                      @click="editLeaveDays()"
                                      icon="CheckIcon"
                                      size="15"
                                    />
                                  </b-input-group-append>
                                </b-input-group>
                              </b-col>
                            </b-row>
                            <small>{{trackLeaveOnDays===true?'Allocated leave days':'Allocated leave hours'}}</small>
                          </div>
                        </div>
                        <div class="d-flex align-items-center pr-bx" >
                          <div class="ml-1">
                            <h5 class="mb-0 font-weight-bolder p" style="color: #171822; font-size:18px" v-if="trackLeaveOnDays">
                              {{ parseFloat(usedLeaveDays).toFixed(1) }} days 
                            </h5>
                            <h5 class="mb-0 font-weight-bolder " style="color: #171822; font-size:18px" v-else>
                              <!-- {{parseFloat(usedLeaveDays).toFixed(2)}} days ({{usedLeaveHours}}hrs) -->
                              {{
                                parseFloat(usedLeaveHours).toFixed(1)
                              }}hrs
                            </h5>
                            <small>Used</small>
                          </div>
                        </div>
                        <div class="d-flex align-items-center mr-1">
                          <div class="ml-1">
                            <h5 class="mb-0 font-weight-bolder " style="color: #171822; font-size:18px" v-if="trackLeaveOnDays">
                              {{ parseFloat(allocatedLeaveDays - usedLeaveDays).toFixed(1) }}
                              days 
                            </h5>
                            <h5 class="mb-0 font-weight-bolder " style="color: #171822; font-size:18px" v-else>
                              <!-- {{parseFloat(allocatedLeaveDays - usedLeaveDays).toFixed(2)}} days ({{remainingLeaveHours}}hrs) -->
                              {{
                                parseFloat(remainingLeaveHours).toFixed(1)
                              }}hrs
                            </h5>
                            <small>Remaining</small>
                          </div>
                        </div>
                        <div class="d-flex align-items-center mr-1">
                          <b-avatar variant="light-warning" rounded>
                            <feather-icon icon="ClockIcon" size="18" />
                          </b-avatar>
                          <div class="ml-1">
                            <h5 class="mb-0 font-weight-bolder " style="color: #171822; font-size:18px">
                              {{ leaveRequestCount }}
                            </h5>
                            <small>Leave Requests</small>
                          </div>
                        </div>
                        <div class="d-flex align-items-center">
                          <b-avatar variant="light-danger" rounded>
                            <feather-icon icon="HeartIcon" size="18" />
                          </b-avatar>
                          <div class="ml-1">
                            <h5 class="mb-0 font-weight-bolder " style="color: #171822; font-size:18px" v-if="trackLeaveOnDays">
                              {{ parseFloat(sicknessLeaveDays).toFixed(1) }} days
                            </h5>
                            <h5 class="mb-0 font-weight-bolder " style="color: #171822; font-size:18px" v-else>
                              {{
                                parseFloat(sicknessLeaveHours).toFixed(1)
                              }}hrs
                            </h5>
                            <small>Sickness Leaves</small>
                          </div>
                        </div>
                      </div>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
          <b-col cols="12" xl="4" class="pt-0 pb-1 pt-xl-1">
            <b-row>
              <b-col
                cols="12"
                md="5"
                class="d-flex justify-content-center justify-content-xl-end"
              >
              </b-col>

              <b-col cols="12" md="7" class=" pr-5 pl-5   my-2 my-md-0">
                <b-button
                  class="btn-block "
                  variant="primary"
                  @click="toggleSideBars()"
                >
                  <span class="align-middle">+ Add Leave</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-overlay>
    </b-card>
    <AddLeaveSidebar
      ref="AddLeaveSidebar"
      :is-add-leave-sidebar-active="isAddLeaveSidebarActive"
      @toggleSideBars="toggleSideBars()"
      @dataRefresh="dataRefresh()"
    />
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BLink,
  BRow,
  BAlert,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BInputGroup,
  BFormGroup,
  BOverlay,
} from 'bootstrap-vue';
import AddLeaveSidebar from '../../../sidebars/userProfileAddLeaveSidebar.vue';
import AdminLeaveAPI from '@/apis/modules/leave_managment_admin';
import MomentMixin from '@/mixins/MomentMixin';
import ErrorMessages from '@/mixins/ErrorMessages';

export default {
  name: 'profile-image-bar',
  mixins: [MomentMixin, ErrorMessages],
  components: {
    AddLeaveSidebar,
    BAlert,
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BBadge,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormGroup,
    BOverlay,
  },
  data() {
    return {
      isAddLeaveSidebarActive: false,
      editLeaveDaysShow: true,
      allocatedLeaveDays: 0,
      allocatedLeaveHours: 0,
      usedLeaveDays: 0,
      usedLeaveHours: 0,
      remainingLeaveHours: 0,
      sicknessLeaveDays: 0,
      sicknessLeaveHours: 0,
      leaveRequestCount: 0,
      loading: false,
      workingHoursPerDay: 0,
      userDetails: {},
      jobRoleName: '',
      editNoOfLeaveDays: '',
      currentPracticeId: '',
      trackLeaveOnDays:''
    };
  },
  methods: {
    toggleSideBars() {
      this.isAddLeaveSidebarActive = !this.isAddLeaveSidebarActive;
      // this.$emit('refreshTables');
      // if (!this.isAddLeaveSidebarActive) {
      //   this.getUserSummaryDetails(this.$route.params.id);
      // }
    },
    dataRefresh(){
      this.$emit('refreshTables');
      this.getUserSummaryDetails(this.$route.params.id);
    },
    async editLeaveDays() {
      try {
        if (!this.editLeaveDaysShow) {
          this.loading = true;
          let payload = {};
          payload.user_id = this.userDetails.id;
          this.trackLeaveOnDays === false?payload.number_of_leaves_per_year = this.editNoOfLeaveDays/this.workingHoursPerDay:payload.number_of_leaves_per_year =this.editNoOfLeaveDays;
          const Response = await AdminLeaveAPI.updateUsersNumberOfLeaves(
            payload
          );
          await this.getUserSummaryDetails(this.userDetails.id);
          this.editLeaveDaysShow = !this.editLeaveDaysShow;
          this.editNoOfLeaveDays = ''
          this.loading = false;
        } else {
          this.editLeaveDaysShow = !this.editLeaveDaysShow;
        }
      } catch (error) {
        this.loading = false;
        this.editLeaveDaysShow = !this.editLeaveDaysShow;
        this.convertAndNotifyError(error);
      }
    },
    async getUserSummaryDetails(id) {
      try {
        this.loading = true;
        const Response = await AdminLeaveAPI.userSummary(id);
        let leave_takable_count = Response.data.data.users[0].leave_takable_count;
        let working_hours_per_day = Response.data.data.working_hours_per_day;
        let user_total_leave_per_year = Response.data.data.user_total_leave_per_year;
        let user_total_sickness_hour = Response.data.data.total_sickness_hours_taken;

        // ((user_total_leave_per_year * working_hours_per_day) - leave_takable_count) / working_hours_per_day
        let total_work_hours = user_total_leave_per_year * working_hours_per_day;
        let remaing = total_work_hours - leave_takable_count;
        let used_sick_days = parseInt(user_total_sickness_hour / working_hours_per_day);
        let used_days = remaing / working_hours_per_day;
        this.usedLeaveDays = used_days;
        this.allocatedLeaveDays = Response.data.data.users[0].number_of_leaves_per_year;
        let number_of_leaves_per_year = Response.data.data.users[0].number_of_leaves_per_year;
        this.userDetails = Response.data.data.users[0].user;
        this.currentPracticeId = await Response.data.data.users[0].practice_id;
        this.jobRoleName = Response.data.data.users[0].job_role.name;
        //let user = Response.data.data.users[0].user
        //this.usedLeaveDays = Response.data.data.used_leaves
        //this.sicknessLeaveDays = Response.data.data.sickness_days_taken
        this.workingHoursPerDay = working_hours_per_day;
        this.allocatedLeaveHours = user_total_leave_per_year * working_hours_per_day;
        this.usedLeaveHours = used_days * working_hours_per_day;
        this.remainingLeaveHours = (number_of_leaves_per_year - used_days) * working_hours_per_day;

        this.sicknessLeaveHours = user_total_sickness_hour;
        this.leaveRequestCount = Response.data.data.pending_request_count;
        this.sicknessLeaveDays = used_sick_days;
        this.trackLeaveOnDays = !!Response.data.data.practice.track_leave_on_days;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.convertAndNotifyError(error);
      }
    },
    calculateUsedDays(data) {
      let count = 0;
      let prac_id = this.currentPracticeId;
      if (data && data.leaves.length > 0) {
        let leaves = data.leaves;
        leaves.forEach(function(obj, key) {
          if (obj.state === 'approved' && obj.practice_id == prac_id) {
            count++;
          }
        });
        return count;
      } else {
        count = 0;
        return count;
      }
    },
    calculatePendingLeave(data) {
      let count = 0;
      if (data && data.leaves.length > 0) {
        let leaves = data.leaves;
        leaves.forEach(function(obj, key) {
          if (obj.state === 'pending') {
            count++;
          }
        });
        return count;
      } else {
        count = 0;
        return count;
      }
    },
  },
  mounted() {
    this.getUserSummaryDetails(this.$route.params.id);
  },
};
</script>

<style scoped>
.text-dark{
  color: #171822;
}
</style>
