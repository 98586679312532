<template>
  <div>
    <ProfileImageBar
        @refreshTables = refreshTables()
    />
    <LeaveRequestsTable ref="LeaveRequests"/>
    <ScheduledLeavesTable ref="ScheduledLeaves"/>
    <BankHolidaysTable />
    <PastLeavesTable ref="PastLeaves"/>
  </div>
</template>

<script>
import ProfileImageBar from './includes/profileImageBar.vue'
import LeaveRequestsTable from './tables/leaveRequests.vue'
import PastLeavesTable from './tables/pastLeaves.vue'
import ScheduledLeavesTable from './tables/scheduledLeaves.vue'
import BankHolidaysTable from './tables/bankHolidays.vue'

import {BAvatar, BBadge, BButton, BCard, BCol, BLink, BRow, BAlert} from 'bootstrap-vue'
export default {
  components: {
    BankHolidaysTable,
    ProfileImageBar,
    LeaveRequestsTable,
    PastLeavesTable,
    ScheduledLeavesTable,
    BAlert,
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BBadge,
  },
  data() {
    return {
    }

  },
  mounted () {

  },

  methods: {
    async refreshTables() {
      await this.$refs.LeaveRequests.tableRefresh()
      await this.$refs.ScheduledLeaves.tableRefresh()
      await this.$refs.PastLeaves.tableRefresh()
    }
  }

}
</script>
